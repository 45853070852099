import { reactive } from 'vue'
import { createStore } from 'vuex'

/*
export const store = reactive({
    currentUserEmail: null,
    currentUid: null,
    currentName: null,
    currentOrdination: null,
    selectedOrdination: ''
})*/

export const store = createStore({
    state: {
        open: false
    },
    mutations: {},
    actions: {},
    getters: {},
})