<template>
    <section>
        <div
            class="relative w-full flex flex-col gap-y-6 z-10 items-center justify-center px-4 md:px-22 lg:px-24 xl:px-32 bg-opacity-image bg-top bg-cover bg-no-repeat md:bg-cover h-[550px] md:h-screen top-0"
        >
            <p
                class="text-lg text-left md:text-center sm:text-3xl italic pb-4 z-10 w-full md:w-2/3 pt-10 md:pt-0"
            >
            {{ objectData.title }}
            </p>
            <img src="/src/assets/home-page/bg2-1.png" alt="" class="w-full md:w-2/3 h-fit opacity-70">
            <img v-if="!store.state.open" src="/src/assets/arrow-down.svg" class="absolute flex bottom-8 left-[45%] md:left-[50%] animate-bounce cursor-pointer" @click="goDown" />
        </div>
        <div
            class="flex flex-col items-center justify-center py-6 md:py-24 px-4 md:px-22 lg:px-24 xl:px-32 gap-12 w-full gap-y-12 xl:gap-y-0 bg-[#FFFAF0]"
            id="second-section"
        >
            <p
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
                class="leading-[2rem] md:leading-10 text-sm md:text-md text-black text-left pb-6"
            >
                {{ objectData.paragraph1 }} <br />
                {{ objectData.paragraph1_2 }}

            </p>
        </div>
        <div
            class="flex flex-col xl:flex-row items-center justify-center py-0 md:py-24 px-0 md:px-22 lg:px-24 xl:px-32 gap-12 bg-green-900/50 w-full gap-y-0 xl:gap-y-0"
        >
            <p
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
                class="leading-[2rem] md:leading-10 px-4 md:px-6 pt-6 pb-8 md:py-16 text-sm md:text-md text-white text-left"
            >
               {{ objectData.paragraph2 }}
                <br />

                {{ objectData.paragraph3 }}

            </p>
            <img
                v-if="objectData.img1"
                :src="`${ objectData.img1 }`"
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
                class="h-auto md:h-auto w-full md:w-1/3 rounded-none md:rounded-md order-first md:order-last"
            />
        </div>
        <div
            class="flex flex-col items-start justify-start pb-6 md:pb-24 px-4 md:px-22 lg:px-24 xl:px-32 gap-12 bg-green-900/50 w-full"
        >
            <p
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
                class="leading-[2rem] md:leading-10 text-sm md:text-md text-white text-left"
            >
                {{ objectData.paragraph4 }}
            </p>
        </div>
        <div
            class="flex flex-col xl:flex-row items-center justify-center py-0 md:py-24 px-0 md:px-22 lg:px-24 xl:px-32 gap-12 bg-[#FFFAF0] w-full gap-y-0 xl:gap-y-0"
        >
            <p
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
                class="leading-[2rem] md:leading-10 px-4 md:px-6 pt-6 pb-8 md:py-16 text-sm md:text-md text-black text-left"
            >
                {{ objectData.paragraph5 }}

            </p>
            <img
                v-if="objectData.img2"
                :src="`${ objectData.img2 }`"
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
                class="h-auto md:h-auto w-full xl:w-1/3 rounded-none md:rounded-md order-first md:order-last rotate-90"
            />
        </div>
        <div
            class="flex flex-col xl:flex-row items-center justify-center py-0 md:py-24 px-0 md:px-22 lg:px-24 xl:px-32 gap-12 bg-green-900/50 w-full gap-y-0 xl:gap-y-0"
        >
            <div>
                <p class="leading-[2rem] md:leading-10 px-4 md:px-6 pt-6 md:pt-16 text-lg text-white text-left" v-if="objectData.p6_title"
                    data-aos="fade-up"
                    data-aos-easing="lienar"
                    data-aos-duration="1000"
                >
                    {{ objectData.p6_title }}
                </p>
                <p
                    data-aos="fade-up"
                    data-aos-easing="lienar"
                    data-aos-duration="1000"
                    class="leading-[2rem] md:leading-10 px-4 md:px-6 pt-6 pb-8 md:pb-16 text-sm md:text-md text-white text-left"
                >
                    {{ objectData.paragraph6 }}

                </p>
            </div>

            <img
                :src="`${ objectData.img3 }`"
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
                class="h-auto md:h-auto w-full md:w-2/5 rounded-none md:rounded-md order-first"
            />
        </div>
        <div
            class="flex flex-col items-start justify-center py-0 md:py-24 px-4 md:px-22 lg:px-24 xl:px-32 gap-12 bg-[#FFFAF0] w-full gap-y-0 xl:gap-y-0"
        >
            <p class="leading-[2rem] md:leading-10 px-0 md:px-6 pt-6 md:pt-16 text-lg text-black text-left" v-if="objectData.p7_title"
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
            >
                {{ objectData.p7_title }}
            </p>
            <p
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
                class="leading-[2rem] md:leading-10 px-0 md:px-6 pt-6 pb-8 text-sm md:text-md text-black text-left"
            >
                {{ objectData.paragraph7 }}

            </p>
            <p class="leading-[2rem] md:leading-10 px-0 md:px-6 pt-0 md:pt-12 text-lg text-black text-left" v-if="objectData.p8_title"
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
            >
                {{ objectData.p8_title }}
            </p>
            <p
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
                class="leading-[2rem] md:leading-10 px-0 md:px-6 pt-6 pb-8 text-sm md:text-md text-black text-left"
            >
                {{ objectData.paragraph8 }}

            </p>
            <p
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
                class="leading-[2rem] md:leading-10 px-0 md:px-6 pt-0 pb-8 md:py-4 text-sm md:text-md text-black text-left"
            >
                {{ objectData.paragraph9 }}

            </p>
        </div>
        <div
            class="flex flex-col items-center justify-center py-0 md:py-24 px-4 md:px-22 lg:px-24 xl:px-32 gap-12 bg-green-900/50 w-full gap-y-0 xl:gap-y-0"
        >
            <p
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
                class="leading-[2rem] md:leading-10 px-0 md:px-6 pt-6 pb-6 md:pb-4 md:pt-0 text-sm md:text-md text-white text-left"
            >
                {{ objectData.paragraph10 }}

            </p>
            <p
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
                class="leading-[2rem] md:leading-10 px-0 md:px-6 pt-0 pb-8 md:pt-4 md:pb-0 text-sm md:text-md text-white text-left"
            >
                {{ objectData.paragraph11 }}

            </p>
        </div>
        <div 
            data-aos="fade-up"
            data-aos-easing="lienar"
            data-aos-duration="1000" 
            class="px-4 md:px-22 lg:px-24 xl:px-32 gap-y-6 text-[#fabb1e] text-sm md:text-lg">
            <div class="flex flex-col md:flex-row items-start border-b-2 border-[#fabb1e] pt-8 md:pt-16 pb-4 gap-4 cursor-default"
            >
                <div 
                    v-for="i in objectData._tags" :key="i"
                >
                    <p class="hover:text-slate-900">{{ i }}</p>
                </div>
            </div>
        </div>
        <div
            data-aos="fade-up"
            data-aos-easing="lienar"
            data-aos-duration="1500" 
            class="flex flex-col items-start text-start text-black py-10 md:pb-24 pt-8 px-4 md:px-22 lg:px-24 xl:px-32 gap-y-3 md:gap-y-6 break-all"
            >
            <h1 class="text-[#fabb1e] text-2xl">LITERATURA</h1>
            <div 
                v-for="j in objectData._literature" :key="j"
            >
                <a :href="`${ j.url }`" target="_blank" class="text-xs md:text-sm hover:underline">
                    {{ j.title }}
                </a>
            </div>
        </div>
    </section>
</template>

<script>
import data from '/src/data.json'
import { store } from '../store.js'

export default {
    name: 'blogDetails',
    data() {
        return {
            objectData: {},
            store
        }
    },
    mounted() {
        this.fetchObjectData();
        console.log(this.objectData)
    },
    methods: {
        fetchObjectData() {
            let url = this.$route.params.url
            this.objectData = data.blogs.find(obj => obj.url === url);
        },
        goDown() {
            const section = document.getElementById("second-section");
            section.scrollIntoView({behavior: "smooth"});
        },
    }
}
</script>

<style>

.bg-opacity-image {
  position: relative;
  overflow: hidden;
}

.bg-opacity-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('/src/assets/bg.png');
  background-size: cover;
  opacity: 0.8;
  z-index: 0;
  transform: scaleY(-1);
}
</style>
