import { createRouter, createWebHistory } from 'vue-router'
import index from '../views/indexPage.vue'
import aboutMe from '../views/aboutMe.vue'
import pregnancyPage from '../views/pregnancyPage.vue'
import blogDetails from '../views/blogDetails.vue'
import commingSoon from '../views/commingSoon.vue'
import notFound from '../views/404.vue'
import birthPage from '../views/birthPage.vue'
import postpartumPage from '../views/postpartumPage.vue'
import blogsList from '../views/blogsList.vue'
import contactPage from '../views/contactPage.vue'

const routes = [
    {
        path: '/',
        name: 'index',
        component: index,
    },
    {
        path: '/o-meni',
        name: 'aboutMe',
        component: aboutMe,
        meta: { title: 'O meni | Zagrljaj Majke' },
    },
    {
        path: '/trudnoca',
        name: 'pregnancyPage',
        component: pregnancyPage,
        meta: { title: 'Trudnoca | Zagrljaj Majke' },
    },
    {
        path: '/blog/:url',
        name: 'blogDetails',
        component: blogDetails,
        props: true,
        meta: { title: 'Blog | Zagrljaj Majke' },
    },
    {
        path: '/comming-soon',
        name: 'commingSoon',
        component: commingSoon,
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'notFound',
        component: notFound,
        meta: { title: 'Nije pronađeno | Zagrljaj Majke' },
    },
    {
        path: '/porod',
        name: 'birthPage',
        component: birthPage,
        meta: { title: 'Porod | Zagrljaj Majke' },
    },
    {
        path: '/postpartum',
        name: 'postpartumPage',
        component: postpartumPage,
        meta: { title: 'Postpartum | Zagrljaj Majke' },
    },
    {
        path: '/blogs',
        name: 'blogsList',
        component: blogsList,
        props: true,
        meta: { title: 'Blogovi | Zagrljaj Majke' },
    },
    {
        path: '/kontakt',
        name: 'contactPage',
        component: contactPage,
        props: true,
        meta: { title: 'Kontakt | Zagrljaj Majke' },
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        if (to.hash) {
            return { selector: to.hash }
        }
        return { x: 0, y: 0 }
    },
})

const defaultTitle = 'Zagrljaj Majke'
router.afterEach((to) => {
    document.title = to.meta.title || defaultTitle
})

export default router
