<template>
    <div
        class="absolute justify-between items-center flex w-full lg:h-auto px-4 md:px-22 lg:px-24 xl:px-32 py-4 top-0 lg:top-0 py-8 font-['Merriweather'] z-20"
        :class="[
            store.state.open
                ? 'relative flex flex-wrap bg-gradient-to-b from-neutral-900 to-neutral-600 lg:bg-inherit transform origin-top-right transition duration-300 fade-in-out h-full pb-[700px]'
                : ' bg-zinc-900/20 bg-blend-multiply',
            this.$route.path === '/blogs' ? 'bg-zinc-900/70' : ''
        ]"
    >
        <router-link
            @click="closeMenu"
            to="/"
            class="flex flex-row items-center pt-0 lg:pt-0 gap-2 lg:gap-6 w-2/3 lg:w-1/3"
        >
            <img
                v-if="this.$route.name == 'blogDetails' && !store.state.open"
                src="/src/assets/logo2.png"
                alt="logo"
                class="logo h-[50px]"
            />
            <img
                v-if="this.$route.name != 'blogDetails' || store.state.open"
                src="/src/assets/logo.png"
                alt="logo"
                class="logo h-[70px]"
            />
            <p
                class="text-xl md:text-2xl font-extrabold italic"
                :class="[

                    this.$route.name == 'blogDetails' && !store.state.open ? 'text-white' : 'text-[#fabb1e]'
                ]"
            >
                Zagrljaj Majke
            </p>
        </router-link>

        <div
            class="lg:hidden flex right-6 sm:right-12 md:right-24 top-16 px-6 pt-0 lg:pt-4 border-none"
        >
            <button
                class="flex items-center rounded font-extrabold text-[#fabb1e] order-last border-none"
                @click="toggle"
            >
                <svg
                    class="open absolute fill-[#fabb1e] h-5 w-5 transform transition duration-1000 ease-in-out border-none"
                    :class="[
                        store.state.open ? 'hidden' : 'open',
                        ,
                        this.$route.name == 'blogDetails' ? 'fill-white' : '',
                    ]"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <title>Menu</title>
                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>

                <svg
                    class="close absolute flex transform transition duration-1000 ease-in-out border-none"
                    :class="store.state.open ? 'close' : 'hidden'"
                    height="18px"
                    version="1.1"
                    viewBox="0 0 14 14"
                    width="18px"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                    <title />
                    <desc />
                    <defs />
                    <g
                        fill="none"
                        fill-rule="evenodd"
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                    >
                        <g
                            fill="#fabb1e"
                            id="Core"
                            transform="translate(-341.000000, -89.000000)"
                        >
                            <g
                                id="close"
                                transform="translate(341.000000, 89.000000)"
                            >
                                <path
                                    d="M14,1.4 L12.6,0 L7,5.6 L1.4,0 L0,1.4 L5.6,7 L0,12.6 L1.4,14 L7,8.4 L12.6,14 L14,12.6 L8.4,7 L14,1.4 Z"
                                    id="Shape"
                                />
                            </g>
                        </g>
                    </g>
                </svg>
            </button>
        </div>
        <div
            class="flex flex-col lg:flex-row w-full lg:justify-end lg:space-x-12 pt-16 lg:pt-0 lg:flex text-lg md:text-lg font-bold lg:text-end items-end lg:items-center"
            :class="[
                store.state.open ? 'flex items-center text-[#fabb1e]' : 'hidden text-[#fabb1e]',
            ]"
        >
            <div
                class="flex flex-col lg:flex-row space-y-12 md:space-y-6 lg:space-y-0 lg:space-x-16 pb-6 lg:pb-0"
            >
                <a
                    href="/o-meni"
                    class="cursor-pointer hover:underline hover:underline-offset-8"
                    :class="[
                        this.$route.path != '/o-meni'
                            ? ''
                            : 'underline underline-offset-8',
                        this.$route.name == 'blogDetails' && !store.state.open ? 'text-white' : '',
                    ]"
                    @click="closeMenu"
                    >O meni</a
                >
                <router-link
                    to="/blogs"
                    class="cursor-pointer hover:underline hover:underline-offset-8"
                    :class="[
                        this.$route.path != '/blogs'
                            ? ''
                            : 'underline underline-offset-8',
                        this.$route.name == 'blogDetails' && !store.state.open ? 'text-white' : '',
                    ]"
                    @click="closeMenu"
                    >Blog</router-link
                >

                <a
                    href="/trudnoca"
                    class="block lg:hidden cursor-pointer hover:underline hover:underline-offset-8"
                    :class="[
                        this.$route.path != '/trudnoca'
                            ? ''
                            : 'underline underline-offset-8',
                        this.$route.name == 'blogDetails' && !store.state.open ? 'text-white' : '',
                    ]"
                    @click="closeMenu"
                    >Trudnoća</a
                >
                <router-link
                    to="/porod"
                    class="block lg:hidden cursor-pointer hover:underline hover:underline-offset-8"
                    :class="[
                        this.$route.path != '/porod'
                            ? ''
                            : 'underline underline-offset-8',
                        this.$route.name == 'blogDetails' && !store.state.open ? 'text-white' : '',
                    ]"
                    @click="closeMenu"
                    >Porod</router-link
                >
                <router-link
                    to="/postpartum"
                    class="block lg:hidden cursor-pointer hover:underline hover:underline-offset-8"
                    :class="[
                        this.$route.path != '/postpartum'
                            ? ''
                            : 'underline underline-offset-8',
                        this.$route.name == 'blogDetails' && !store.state.open ? 'text-white' : '',
                    ]"
                    @click="closeMenu"
                    >Postpartum</router-link
                >

                <router-link
                    to="/kontakt"
                    class="cursor-pointer hover:underline hover:underline-offset-8"
                    :class="[
                        this.$route.path != '/kontakt'
                            ? ''
                            : 'underline underline-offset-8',
                        this.$route.name == 'blogDetails' && !store.state.open ? 'text-white' : '',
                    ]"
                    @click="closeMenu"
                    >Kontakt</router-link
                >
            </div>
        </div>
    </div>
</template>

<script>
import { store } from '../store.js'

export default {
    name: 'appHeader',
    data() {
        return {
            open: false,
            store
        }
    },
    methods: {
        closeMenu() {
            store.state.open = false
        },
        toggle() {
            store.state.open = !store.state.open
        },
    },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap');
</style>
