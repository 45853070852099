<template>
<transition name="fade-out">
    <section>
        <div
            class="w-full flex px-4 md:px-22 lg:px-24 xl:px-32 bg-[url('/src/assets/home-page/bg.png')] bg-center bg-neutral-900/70 bg-blend-multiply bg-[#7a7a79] bg-cover h-[100vh] sm:h-[700px] md:h-[100vh] top-0"
        >
            <div
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1500"
                class="flex flex-col justify-center self-center rounded-md lg:w-full 2xl:w-4/5 h-fit lg:h-1/2 pt-24 pb-6"
            >
                <div class="flex flex-col gap-y-4 text-left leading-[3rem] xl:leading-[3.5rem] text-[#fabb1e]/70"
                >
                <p class="text-xl xl:text-4xl font-['Merriweather']">
                    Uronite zajedno samnom u svijet poroda.
                </p>
                <p class="text-xl xl:text-3xl font-['Merriweather']">
                    U svijet gdje je porod blagoslovljen događaj prepun emocija!
                </p>
            </div>
            </div>
            <img v-if="!store.state.open" src="/src/assets/arrow-down.svg" class="absolute flex bottom-8 left-[45%] md:left-[50%] animate-bounce cursor-pointer" @click="goDown" />
        </div>

        <div
            class="pt-12 pb-0 md:py-36 px-0 md:px-22 lg:px-24 xl:px-32 flex flex-col xl:flex-row justify-center w-full gap-x-12 md:gap-x-20 gap-y-12 xl:gap-y-0" id="second-section"
        >
            <div
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
                class="flex flex-col items-start gap-0 md:gap-8 w-full md:max-w-xl shrink"
            >
                <div
                    class="flex flex-row items-center gap-6 px-4 md:px-0 pb-6 md:pb-0"
                >
                    <img
                        src="/src/assets/dot3.png"
                        class="h-10 rotate-[-45deg] md:flex hidden"
                    />
                    <h1 class="text-2xl md:text-3xl text-[#EBAC0D] font-bold">
                        Moje ime je Laura
                    </h1>
                </div>
                <p class="text-left text-black leading-8 text-sm 2xl:text-md px-4 md:px-0">
                    Rođena sam 1995. godine u Vinkovcima, i
                    ubrzo još kao beba stigla sam s mojim dragim roditeljima u
                    Istru. I tu sam i ostala. Živim s obitelji u Istri. Ono što
                    je bila molitva moga srca u srednjoj školi jest upoznati
                    srodnu dušu i otkriti svoj poziv - ono čime se želim baviti.
                    I bi tako. Gospodin je bio brz, i već na prvoj godini
                    fakulteta upoznala sam ljubav svog života. Nakon tri godine
                    udala sam se i godinu poslije postala majka. Preko moga
                    braka i moje dječice Gospodin me brusi u strpljenju,
                    pravednosti, razboritosti i vjeri i uči me u svakoj
                    situaciji, pa i onoj najlošijoj, tražiti nešto dobro.
                </p>
                <a
                    href="/o-meni"
                    class="bg-[#EBAC0D] mx-4 mt-4 md:mt-0 md:mx-0 px-6 py-2 text-white border border-[#EBAC0D] hover:bg-[#E3A60C] hover:border-[#E3A60C] hover:text-white rounded-none md:rounded-md"
                    >Pročitaj više</a
                >
            </div>
            <div class="w-full md:w-2/3 2xl:w-1/3">
                <img
                    src="/src/assets/home-page/profile2.jpg"
                    alt="profil"
                    data-aos="fade-up"
                    data-aos-easing="lienar"
                    data-aos-duration="1000"
                    class="h-fit w-full rounded-none md:rounded-md z-0 md:z-[-1]"
                />
            </div>
        </div>

        <div
            class="flex flex-col xl:flex-row items-center justify-center px-6 sm:px-12 md:px-24 xl:px-32 bg-opacity-image w-full py-24 md:py-36 gap-y-10 xl:gap-y-0"
        >
            <div
                class="flex flex-col lg:flex-row items-center gap-6 xl:gap-24 w-full md:w-auto z-20"
            >
                <HomeCard :services="services" />
            </div>
        </div>

        <div
            class="flex flex-col items-center pt-12 pb-16 md:py-24 overflow-hidden"
        >
            <div
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
                class="flex flex-row items-center gap-6 pb-8 md:pb-16"
            >
                <img
                    src="/src/assets/dot3.png"
                    class="h-10 rotate-[-45deg] hidden md:flex"
                />
                <h1 class="text-3xl text-[#EBAC0D] font-bold">Blog</h1>
            </div>
            <BlogSlider
                :blogs="blogs"
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1500"
            />
        </div>
    </section>
    </transition>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;1,300&display=swap');

section {
    font-family: 'Merriweather', serif;
}
.fade-out-enter-active,
.fade-out-leave-active {
  transition: opacity 0.5s;
}

.fade-out-enter,
.fade-out-leave-to {
  opacity: 0;
}

.bg-opacity-image {
  position: relative;
  overflow: hidden;
}

.bg-opacity-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('/src/assets/bg.png');
  background-size: cover;
  background-color: rgb(20 83 45);
  opacity: 0.5;
  z-index: 0;
}

</style>

<script>
import BlogSlider from '/src/components/BlogSlider.vue'
import HomeCard from '/src/components/HomeCard.vue'
import data from '/src/data.json'
import { store } from '../store.js'

export default {
    data() {
        return {
            store,
            blogs: [],
            services: [
                {
                    title: 'Trudnoća',
                    img: '/assets/home-page/trudnoca.png',
                    route: '/trudnoca',
                    alt: 'logo-trudnoca'
                },
                {
                    title: 'Porod',
                    img: '/assets/home-page/logo.png',
                    route: '/porod',
                    alt: 'logo-porod'
                },
                {
                    title: 'Postpartum',
                    img: '/assets/home-page/postpartum.png',
                    route: '/postpartum',
                    alt: 'logo-postpartum'
                },
            ],
        }
    },
    components: {
        BlogSlider,
        HomeCard,
    },
    methods: {
        goDown() {
            const section = document.getElementById("second-section");
            section.scrollIntoView({behavior: "smooth"});
        },
        getData() {
            this.blogs = data.blogs;
            console.log(this.blogs)
        }
    },
        mounted() {
        this.getData();
    }
}
</script>
