import { createApp } from 'vue'
import './style.css'
import '../src/index.css'
import App from './App.vue'
import AOS from 'aos'
import 'aos/dist/aos.css'
import router from './router/index'

createApp(App).use(router).mount('#app')

AOS.init()
AOS.refresh()
