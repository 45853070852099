<template>
    <section>
        <div class="flex flex-col w-full px-4 md:px-22 lg:px-24 xl:px-32">
            <div class="flex flex-row justify-start items-center gap-6 pb-4 md:pb-10 pt-28 sm:pt-40">
                <img
                        src="/src/assets/dot3.png"
                        class="h-10 rotate-[-45deg] hidden md:flex"
                    />
                <h1 class="text-2xl xl:text-4xl text-left text-[#EBAC0D] leading-[3rem] my-6">BLOGOVI</h1>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 justify-around gap-6 lg:gap-4 xl:gap-10 pb-12 md:pb-24 w-fit">
                <BlogCard :blogs="blogs" />
            </div>
        </div>
    </section>
</template>

<script>
import BlogCard from '/src/components/BlogCard.vue'
import data from '/src/data.json'

console.log(data.blogs)

export default {
    name: 'blogsList',
    data() {
        return {
            blogs: [],
        }
    },
    components: {
        BlogCard
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.blogs = data.blogs;
            console.log(this.blogs)
        }
    }
}
</script>
