<template>
    <transition name="fade-out">    
        <section>
            <div class="bg-gray-300/50">
                <div
                    class="w-full flex px-4 md:px-22 lg:px-24 xl:px-32 bg-[url('/src/assets/about-me/Profil.jpg')] bg-center bg-cover bg-blend-multiply bg-[#5e5d5d] h-fit md:h-[100vh] top-0"
                >
                    <div
                        data-aos="fade-up"
                        data-aos-easing="lienar"
                        data-aos-duration="1000"
                        class="flex flex-col self-center items-start rounded-md w-full 2xl:w-2/3 h-fit px-0 py-6 xl:p-16 mt-32 md:mt-16"
                    >
                        <p
                            class="text-xl xl:text-3xl text-left text-[#EBAC0D] leading-[3rem]"
                        >
                            Moje ime je Laura
                        </p>
                        <p
                            class="leading-[2rem] md:leading-10 pb-6 pt-3 xl:pt-4 xl:pb-0 text-sm md:text-md text-white text-left"
                        >
                            Moje ime je Laura. Rođena sam 1995. godine u Vinkovcima,
                            i ubrzo još kao beba stigla sam s mojim dragim
                            roditeljima u Istru. I tu sam i ostala. Živim s obitelji
                            u Istri. Ono što je bila molitva moga srca u srednjoj
                            školi jest upoznati srodnu dušu i otkriti svoj poziv -
                            ono čime se želim baviti. I bi tako. Gospodin je bio
                            brz, i već na prvoj godini fakulteta upoznala sam ljubav
                            svog života. Nakon tri godine udala sam se i godinu
                            poslije postala majka. Preko moga braka i moje dječice
                            Gospodin me brusi u strpljenju, pravednosti,
                            razboritosti i vjeri i uči me u svakoj situaciji, pa i
                            onoj najlošijoj, tražiti nešto dobro.
                        </p>
                    </div>
                <img src="/src/assets/arrow-down.svg" class="absolute hidden md:flex items-center text-white bottom-8 left-[50%] animate-bounce cursor-pointer" @click="goDown" />

                </div>
            </div>

            <div
                class="flex flex-col xl:flex-row items-center justify-center py-0 lg:py-24 px-0 md:px-22 lg:px-24 xl:px-32 gap-12 bg-green-900/50 w-full gap-y-12 xl:gap-y-0"
                id="second-section"
            >
                <p
                    data-aos="fade-up"
                    data-aos-easing="lienar"
                    data-aos-duration="1000"
                    class="leading-[2rem] md:leading-10 px-4 pt-0 pb-8 md:py-16 text-sm md:text-md text-white text-left"
                >
                    Moj nevjerojatni suprug uvijek mi je velika podrška i oslonac, a
                    moji porodi i moja ljubljena djeca omogućili su mi da bolje
                    upoznam sebe, da spoznam svoje dobre i loše strane, i probudili
                    u meni poziv za pomaganjem ženama i trudnicama. Oduvijek sam s
                    divljenjem gledala trudnice, a bebe su u meni budile majčinske i
                    one najdivnije osjećaje. Sjećam se u magli kada sam s 4 godine
                    dobila mog dragog bracu, jako me veselilo što imamo bebu u kući,
                    željela sam da nikad ne naraste, za razliku od mojih roditelja
                    koji nisu baš s njim mogli puno spavati. 🤣 <br />
                    Oduvijek sam jedva čekala osjetiti kako je to biti trudna i
                    donijeti na svijet to malo stvorenje. Ali naravno, dok ne
                    dobiješ svoju bebu mnogo toga ne znaš i taj val neznanja me s
                    prvim porodom zapljusnuo. Nakon što sam s 22 godine rodila prvo
                    dijete, uslijedio je moj prvi postpartum i totalni šok,
                    nepripremljenost, zbunjenost. Unatoč tome, izronila sam ubrzo i
                    malo pomalo učila sam i postala sigurnija i opuštenija.
                </p>
                <img
                    src="/src/assets/home-page/profil2.jpg"
                    data-aos="fade-up"
                    data-aos-easing="lienar"
                    data-aos-duration="1000"
                    class="h-auto md:h-auto w-full sm:w-[60%] md:w-4/5 xl:w-[40%] p-0 xl:pt-8 rounded-md order-first md:order-last"
                />
            </div>

            <div
                class="flex flex-col lg:flex-row justify-center items-center py-0 sm:py-10 lg:py-24 px-0 sm:px-12 md:px-24 xl:pr-80 xl:pr-32 xl:py-40"
            >
                <img
                    src="/src/assets/about-me/kids.jpg"
                    class="relative xl:absolute w-full sm:w-[450px] lg:w-[600px] h-auto sm:right-24 xl:right-20 2xl:right-48 3xl:right-80 z-0 md:z-[-1] rounded-none md:rounded-md"
                    data-aos="fade-up"
                    data-aos-easing="lienar"
                    data-aos-duration="1500"
                />
                <div
                    class="flex w-full bg-[#FFFAF0] h-fit 2xl:h-fit rounded-none md:rounded-md mr-0 xl:mr-[200px] 2xl:mr-[450px]"
                    data-aos="fade-up"
                    data-aos-easing="lienar"
                    data-aos-duration="1000"
                >
                    <p
                        class="leading-[2rem] md:leading-10 text-black text-sm md:text-md text-start px-4 py-6 xl:p-12 h-auto rounded-md"
                    >
                        Završila sam Učiteljski fakultet u Rijeci, tako da sam po
                        struci magistar ranog i predškolskog odgoja i obrazovanja,
                        odnosno odgajatelj, ali već s prvim porodom postala sam
                        svjesna da možda to nije za mene. Iako sam se nalazila dosta
                        u tom zanimanju, kroz moje porode i moju djecu rodila se
                        neka nova ja. Zaljubila sam se potpuno u svijet trudnica,
                        rodilja, majki i shvatila koliko je predivno biti podrška i
                        pomoć ženama u njihovom najosjetljivijem periodu.
                        <br /><br />
                        Iza mene tri su vlastita poroda koja su me obogatila na
                        mnoge načine. Koja su mi dala mnogo iskustva i znanja, i
                        koja su mi zapravo kao početni temelj moje buduće misije.
                        Misije u kojoj želim nadograditi i umnožiti znanja i dalje
                        ih širiti tako da trudnoća, porod i postpartum za svaku ženu
                        bude nešto istinski prekrasno. Blagoslovljena sam prilično
                        laganim i bržim porodima koji su mi ostali u lijepom
                        sjećanju. Voljela bih da sve žene postanu svjesne da
                        Gospodin od nas ne traži da porod doživimo kao ogromnu
                        patnju i traumu, već upravo suprotno. Njegova je želja da
                        porod bude blažen, moćan, prekrasan i, ukoliko je moguće,
                        prirodan događaj u kojem se rađa jedna nova ljudska duša i
                        jedna nanovo rođena žena - odnosno majka.
                    </p>
                </div>
            </div>

            <div
                class="flex flex-col md:flex-row justify-center items-center px-0 md:px-22 lg:px-24 xl:px-32 gap-6 lg:gap-12 bg-green-900/50 w-full gap-y-0 md:gap-y-12 xl:gap-y-0"
            >
                <div
                    class="w-auto text-sm md:text-md leading-[2rem] text-white text-left px-4 pb-8 pt-8 sm:px-2 sm:py-4"
                    data-aos="fade-up"
                    data-aos-easing="lienar"
                    data-aos-duration="2000"
                >
                    Dvadeseto stoljeće i zadnja desetljeća pretjerano su počela
                    medikalizirati porode, ono prirodno i ono nagonsko nekako je
                    uspjelo nestati. Taj mentalitet gdje je porod bolest i gdje
                    gotovo svaki zahtijeva nekakve lijekove mora se polako
                    mijenjati. I vjerujem da hoće, vjerujem da Gospodin želi vratiti
                    porodu ono što mu je i namjenio. Da se poštuje ženu, njene
                    želje, njene nagone, njene osjećaje. Da se shvati koliko je
                    porod povezan s emocijama, koliko je porod zapravo psihološki i
                    emocionalni proces, a mnogo puta to se zanemari i ženu svede na
                    objekt koji se porađa. To ne smijemo dopustiti drage moje žene.
                    Nitko vas ne porađa, vi se porađate, drugi su tu kao podrška i
                    pomoć kada je potrebno.
                </div>
                <img
                    src="/src/assets/home-page/Profil.jpg"
                    class="w-full md:w-[300px] xl:w-[400px] h-auto order-first"
                    data-aos="fade-up"
                    data-aos-easing="lienar"
                    data-aos-duration="1000"
                />
            </div>

            <div
                class="flex flex-col justify-center items-center py-8 md:py-24 px-4 sm:px-12 md:px-24 xl:px-80 gap-12 bg-[#FFFAF0] w-full gap-y-4 lg:gap-y-12 xl:gap-y-0"
            >
                <p
                    class="pb-3 md:pb-6 text-black text-sm md:text-md leading-[2rem] lg:leading-[3rem] text-left md:text-center"
                    data-aos="fade-up"
                    data-aos-easing="lienar"
                    data-aos-duration="1000"
                >
                    Zahvalna sam Bogu što je djelovao kroz moj brak, moje porode,
                    moju djecu, moj svaki djelić života i doveo me na ovaj predivan
                    put doule.
                </p>
                <p
                    class="pb-3 md:pb-6 text-black text-sm md:text-md leading-[2rem] lg:leading-[3rem] text-left md:text-center"
                    data-aos="fade-up"
                    data-aos-easing="lienar"
                    data-aos-duration="1000"
                >
                    Drage žene, trudnice, majke, kraljice, tu sam za vas. Kao doula,
                    podrška, edukator, rame za plakanje. Želja moga srca jest
                    umiriti svaki vaš strah “jer nije nam Bog dao duha
                    bojažljivosti, nego snage, ljubavi i razbora.” Želim vam biti
                    podrška u fizičkom, psihičkom i emocionalnom smislu - biti
                    jednostavno doula - ona koja služi ženi.
                </p>
                <p
                    class="text-xl md:text-2xl cursive text-[#fabb1e] text-left md:text-center"
                    data-aos="fade-up"
                    data-aos-easing="lienar"
                    data-aos-duration="1000"
                >
                    Od srca vam šaljem zagrljaj, Laura.
                </p>
            </div>
        </section>
    </transition>
</template>

<script>
export default {
    name: 'aboutMe',
    methods: {
        goDown() {
            const section = document.getElementById("second-section");
            section.scrollIntoView({behavior: "smooth"});
        }
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;1,300&display=swap');

section {
    font-family: 'Merriweather', serif;
}
.fade-out-enter-active,
.fade-out-leave-active {
  transition: opacity 0.5s;
}

.fade-out-enter,
.fade-out-leave-to {
  opacity: 0;
}

</style>
