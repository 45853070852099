<template>
    <Splide :options="splide" class="splide">
        <SplideSlide
            v-for="(blog, i) in blogs"
            :key="i"
            class="splide__track flex justify-center"
        >
            <ul
                class="splide__list flex flex-row flex-wrap w-full md:w-[500px] bg-card-image bg-cover rounded-none md:rounded-md"
            >
                <li
                    class="splide__slide flex flex-col items-center justify-between pb-8 md:pb-6 w-fit md:w-full"
                >
                    <div class="w-full h-auto">
                        <!-- <img
                            :src="`${blog.img}`"
                            :alt="`${blog.alt}`"
                            class="rounded-none md:rounded-t-md"
                        /> -->
                    </div>
                    <div
                        class="flex flex-wrap text-lg lg:text-xl text-black font-medium max-w-[300px] lg:max-w-[380px] w-full"
                    >
                        #{{ blog.title }}
                    </div>
                    <div
                        class="text-md text-black font-medium mx-6 sm:mx-16 md:mx-24 lg:mx-16 italic"
                    >
                        {{ blog.subtitle }}
                    </div>
                    <router-link
                        :to="{
                            name: 'blogDetails',
                            params: {
                                title: blog.title,
                                subtitle: blog.subtitle,
                                url: blog.url,
                            },
                        }"
                        class="flex flex-self-end bg-[#dbab32] mx-6 lg:mx-16 mt-4 md:mt-0 px-6 py-2 text-white border border-[#EBAC0D] hover:bg-[#E3A60C] hover:border-[#E3A60C] hover:text-white rounded-none md:rounded-md"
                        @click="scrollToTop"
                        >Pročitaj više</router-link
                    >
                </li>
            </ul>
        </SplideSlide>
    </Splide>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import '@splidejs/vue-splide/css'
export default {
    name: 'BlogSlider',
    props: {
        blogs: {
            type: Array,
        },
    },
    data() {
        return {
            splide: {
                type: 'slide',
                width: '60%',
                height: '500px',
                cover: 'true',
                perPage: 2,
                // arrows: true,
                // arrowPath: 'M40 9L25 0.339746V17.6603L40 9ZM26.5 7.5L0 7.5V10.5L26.5 10.5V7.5Z',
                fill: 'none',
                pagination: true,
                paginationDirection: 'ltr',
                rewind: true,
                gap: 15,
                rewindspeed: 2000,
                breakpoints: {
                    1715: {
                        perPage: 2,
                        width: '70%',
                    },
                    1435: {
                        perPage: 2,
                        width: '80%',
                    }, 
                    1250: {
                        perPage: 2,
                        width: '85%',
                        height: '500px'
                    },
                    1185: {
                        perPage: 2,
                        width: '80%',
                        height: '410px'
                    },
                    1024: {
                        perPage: 2,
                        width: '85%',
                        height: '375px',
                        gap: 6
                    },
                    920: {
                        width: '90%',
                        height: '350px'
                    },
                    800: {
                        height: '330px'
                    },
                    768: {
                        rewind: false,
                        focus: 'center',
                        perMove: 1,
                        perPage: 1,
                        arrows: false,
                        width: '100vw',
                        height: '100%',
                        // height: '375px',
                        gap: 6
                    },
                },
            },
        }
    },
    components: {
        Splide,
        SplideSlide,
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },
    }
}
</script>

<style>
.splide__pagination {
    bottom: -2em;
}

.splide__pagination__page {
    background: rgb(100, 98, 98);
}

.splide__pagination__page.is-active {
    background: #fabb1e;
}


.splide__arrow {
    display: none;
}

.splide.is-focus-in .splide__arrow:focus {
    outline: none;
}

button:focus {
    outline: none;
}

@media (max-width: 768px) {
    .splide-list {
        width: 100%;
    }
}

.bg-card-image {
  position: relative;
  overflow: hidden;
}

.bg-card-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('/src/assets/card-bg.jpg');
  background-size: cover;
  background-color: rgb(20 83 45);
  opacity: 0.5;
  z-index: 0;
}

.splide__slide,
.bg-card-image.bg-card-image::before {
    height: 500px;
    width: 500px;
    margin: auto;

  @media (max-width: 1185px) {
    width: 410px;
    height: 410px;
  }
  @media (max-width: 1024px) {
    width: 375px;
    height: 375px;
  } 
  @media (max-width: 920px) {
    width: 350px;
    height: 350px;
  } 
  @media (max-width: 800px) {
    width: 330px;
    height: 330px;
  }   
  @media (max-width: 768px) {
    height: 375px;
    width: 375px;
  } 
}
</style>
