<template>
    <div
        class="flex flex-col items-center justify-center w-[250px] md:w-[300px] h-[250px] md:h-[320px] lg:w-[320px] bg-white hover:bg-[#FFFAF0] border-[2px] border-[#EBAC0D] p-6 rounded-full transition-all duration-500 ease-out hover:scale-[1.05] shadow-[0_25px_77px_-25px_rgba(0,0,0,0.32)] cursor-pointer"
        v-for="(service, i) in services"
        :key="i"
    >
        <a :href="`${service.route}`">
            <img :src="`${service.img}`" :alt="`${service.alt}`" class="h-[80px] sm:h-[140px] md:h-[150px] mb-6 m-auto" />
            <h1 class="text-2xl font-bold text-[#EBAC0D]">
                {{ service.title }}
            </h1>
        </a>
    </div>
</template>

<script>
export default {
    name: 'HomeCard',
    props: {
        services: {
            type: Array,
            default: () => {},
        },
    },
}
</script>
